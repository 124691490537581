import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, Paper, Tooltip, Typography } from '@material-ui/core';
import { LocalOfferOutlined } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert } from '@material-ui/lab';
import { CustomerClient, getFirst, isValid, isValidWithoutData } from 'clients';
import BotClient from 'clients/BotClient';
import clsx from 'clsx';
import ErrorProductsPayment from 'components-v2/mocules/ErrorProductsPayment';
import ModalListProduct from 'components-v2/mocules/ModalListProduct';
import { ButtonDefault, LinkComp } from 'components/atoms';
import CustomModal from 'components/mocules/CustomModal';
import { ARR_REMOVE_PRODUCT, BRAND_NAME, HTTP_STATUS, PAYMENT_METHOD, PAYMENT_METHOD_NEED_WAITING, TYPE_CUSTOMER_BY_COLOR } from 'constants/Enums';
import { ERROR_CODE_CART, ERROR_PRODUCTS } from 'constants/ErrorCart';
import { CART_NEW_GIFT_ICON } from 'constants/Images';
import { CART_URL, TERMS_URL, THANKYOU_URL, WAITING_PAYMENT } from 'constants/Paths';
import { useAuth, useCart, useProduct } from 'context';
import { useModal } from 'hooks';
import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useMemo, useState } from 'react';
import { CartService } from 'services';
import AddressService from 'services/AddressService';
import { FEATURE_SHOW_BUTTON_VERIFY_CART, LANG_DEFAULT } from 'sysconfig';
import { FormatNumber, NotifyUtils, gtag } from 'utils';
import { formatCurrency, formatNumber } from 'utils/FormatNumber';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import Insider from 'utils/Insider';
import fbpixel from 'utils/fbpixel';
import tiktok from 'utils/tiktok';
import { useStore } from 'zustand-lib/storeGlobal';
import useMobileV2 from 'zustand-lib/storeMobile';
import useSellers from 'zustand-lib/useSellers';
import VerifyPaymentDiablog from './VerifyPaymentDiablog';
import styles from './styles.module.css';
import validateForm from './validateForm';

const ERROR_REDIRECT_TO_CART = [
  ERROR_CODE_CART.VOUCHER_CODE_INVALID,
  ERROR_CODE_CART.VOUCHER_NOT_MATCH,
  ERROR_CODE_CART.COMBO_INVALID,
  ERROR_CODE_CART.TYPE_INVALID,
  ERROR_CODE_CART.QUANTITY_INVALID,
  ERROR_CODE_CART.SKU_MISSING,
  ERROR_CODE_CART.SKU_NOT_FOUND,
  ERROR_CODE_CART.TOTAL_PRICE_NOT_MATCH,
  ERROR_CODE_CART.LIMIT_SKU,
];

// comp thanh toán , sticky
const CheckoutSticky = ({
  data,
  dataCustomer,
  onSetError,
  isMobile,
  onLoading,
  isSaveInfo,
  user,
  invoice,
  invoices = [],
  cart,
  currentAddress,
  deliveryLimitData, // savedInfo,
  translateCheckout,
}) => {
  const { t: translateAlertAction } = useTranslation('alert-action');
  const {
    redeemCode = [],
    price = 0,
    totalPrice = 0,
    deliveryMethodFee = 0,
    paymentMethodFee = 0,
    totalItemSelected = 0,
    // discount = 0,
    updateCart,
    redeemApplyResult = [],
    cartNo,
    extraFee = null,
    extraFeeNote = null,
    isOverWeightOrVolume,
    cartItems,
    // subPrice = 0,
    errProducts = [],
    removeCartItem,
    selectCartItem,
    removeImportant,
    totalBuymedServiceFee = 0,
  } = useCart();

  // multi voucher
  const redeemRs = redeemApplyResult && redeemApplyResult?.filter((item) => !item?.autoApply && item?.canUse === true);
  const autoRedeemRs = redeemApplyResult && redeemApplyResult?.filter((item) => item?.autoApply && item?.canUse === true);

  // const { code, discountValue: discountValueManual = 0, gifts: giftsManual = null } = redeemRs || {};
  // const { discountValue: discountValueAuto = 0, gifts: giftsAuto = null } = autoRedeemRs || {};
  // const totalGiftManual = (giftsManual?.length > 0 && giftsManual.reduce((a, b) => a + b.quantity, 0)) || 0;
  // const totalGiftAuto = (giftsAuto?.length > 0 && giftsAuto.reduce((a, b) => a + b.quantity, 0)) || 0;

  const getSellerByCode = useSellers((state) => state.getSellerByCode);
  const getSellerByCodeSync = useSellers((state) => state.getSellerByCodeSync);
  const { handleRedCustomer } = useAuth();
  const debt = useStore((state) => state.getDebt());
  const { beta } = useMobileV2();
  const { clearMapProduct } = useProduct();
  const [showModal] = useModal();
  const router = useRouter();
  const [showConfirmModal, toggleConfirm] = useModal(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [checkCondition, setCheckCondition] = useState({
    checked: true,
  });

  const customerDeliveryInfo = useMemo(
    () => dataCustomer?.deliveryMethods?.find((item) => item.code === dataCustomer?.deliveryMethod) || null,
    [dataCustomer],
  );
  const customerPaymentInfo = useMemo(
    () => dataCustomer?.paymentMethods?.find((item) => item.code === dataCustomer?.paymentMethod) || null,
    [dataCustomer],
  );

  const locale = Cookies.get('NEXT_LOCALE') || LANG_DEFAULT;

  const deliveryData = dataCustomer.deliveryMethods.find((item) => item.code === dataCustomer.deliveryMethod) || {};
  const deliveryFeeName = useMemo(() => deliveryData ? deliveryData[`name_${locale}`] ?? deliveryData.name ?? '' : '', [deliveryData, locale]);
  const deliveryFeeSubTitle = useMemo(() => deliveryData?.subTitle ?? '', [deliveryData]);

  const paymentName = useMemo(() => customerPaymentInfo ? customerPaymentInfo[`name_${locale}`] ?? customerPaymentInfo.name ?? '' : '', [customerPaymentInfo, locale])
  const paymentSubTitle = useMemo(() => customerPaymentInfo ? customerPaymentInfo[`subTitle_${locale}`] ?? customerPaymentInfo.subTitle ?? '' : '', [customerPaymentInfo, locale]);

  const { district, province, ward } = currentAddress || {};
  let deliveryLimitMessage = '';
  let deliveryLimitCheck = false;

  // const needToBuy = () => {
  //   if (extraFee) {
  //     if (data.customerProvinceCode === '79' || data.customerProvinceCode === '01') return 1500000 - price;
  //     return 2000000 - price;
  //   }
  //   return 0;
  // };

  const handleCheckCondition = (event) => {
    setCheckCondition({ ...checkCondition, [event.target.name]: event.target.checked });
  };

  const GreenCheckbox = React.memo((props) => <Checkbox classes={{ root: styles.checkbox }} color="default" {...props} />);
  const GreenCheckBoxElement = (
    <GreenCheckbox checked={checkCondition.checked} onChange={handleCheckCondition} name="checked" data-test="checkbox-checkout-terms-of-use" />
  );
  const LabelConfirm = (
    <span className={styles.check_agree_txt}>
      {translateCheckout('i_agree_with')}
      <LinkComp href={TERMS_URL} color="#00b46e" target>
        &nbsp;{translateCheckout('condition_of_use')}
      </LinkComp>
    </span>
  );

  const validateSubmit = (formData) => {
    try {
      validateForm(formData, onSetError, translateAlertAction);
      return true;
    } catch (error) {
      NotifyUtils.error(error.message);
      return false;
    }
  };

  const handleVerify = async () => {
    onLoading(true);
    const formValue = {
      ...data,
      ...dataCustomer,
      invoice: {
        ...invoice,
        email: data.customerEmail || dataCustomer.customerEmail,
      },
      cartNo,
    };
    Object.keys(formValue).forEach((key) => {
      if (typeof formValue[key] === 'string') formValue[key] = formValue[key].trim();
    });
    if (!validateSubmit(formValue)) {
      onLoading(false);
      return;
    }
    if (!checkCondition.checked) {
      onLoading(false);
      NotifyUtils.error(translateCheckout('message_not_agree_condition_of_use'));
      return;
    }

    // validate provinces

    const districts = await AddressService.getDistrictsByProvince(formValue.customerProvinceCode);
    if (!districts) {
      NotifyUtils.error(translateCheckout('message_not_found_ward_district'));
      onLoading(false);
      return;
    }

    const isExistDistrict = districts.find((item) => item.value === formValue.customerDistrictCode);

    if (!isExistDistrict) {
      NotifyUtils.error(translateCheckout('message_not_found_ward_district'));
      onLoading(false);
      return;
    }
    // Validate
    const dataValidate = {
      ...formValue,
      totalPrice,
      voucherCode: redeemCode[0],
      skus: cartItems?.filter((item) => item.isSelected || item.type === 'GIFT')?.map(({ sku, quantity, type }) => ({ sku, type, quantity })) || [],
    };

    const validateRs = await CartService.validateCart({ body: dataValidate });

    NotifyUtils.success(JSON.stringify(validateRs));
    BotClient.sendMesageBotOrderTh({ message: `Test validate - ${user?.phone || ''} : ${JSON.stringify(validateRs || {})}` });
    onLoading(false);
  };

  const handleSubmit = async () => {
    onLoading(true);
    clearMapProduct();
    const formValue = {
      ...data,
      ...dataCustomer,
      invoice: {
        ...invoice,
        email: data.customerEmail || dataCustomer.customerEmail,
      },
      cartNo,
    };
    Object.keys(formValue).forEach((key) => {
      if (typeof formValue[key] === 'string') formValue[key] = formValue[key].trim();
    });
    if (!validateSubmit(formValue)) {
      onLoading(false);
      return;
    }
    if (!checkCondition.checked) {
      onLoading(false);
      NotifyUtils.error(translateCheckout('message_not_agree_condition_of_use'));
      return;
    }

    // validate provinces

    const districts = await AddressService.getDistrictsByProvince(formValue.customerProvinceCode);
    if (!districts) {
      NotifyUtils.error(translateCheckout('message_not_found_ward_district'));
      onLoading(false);
      return;
    }

    const isExistDistrict = districts.find((item) => item.value === formValue.customerDistrictCode);

    if (!isExistDistrict) {
      NotifyUtils.error(translateCheckout('message_not_found_ward_district'));
      onLoading(false);
      return;
    }
    // Validate
    const dataValidate = {
      ...formValue,
      totalPrice,
      voucherCode: redeemCode[0],
      skus: cartItems?.filter((item) => item.isSelected || item.type === 'GIFT')?.map(({ sku, quantity, type }) => ({ sku, type, quantity })) || [],
    };

    const validateRs = await CartService.validateCart({ body: dataValidate });
    if (!isValidWithoutData(validateRs)) {
      // nếu voucher, giỏ hàng lỗi sẽ tự động redirect về giỏ Hàng
      // APO-1559
      if (ERROR_REDIRECT_TO_CART.includes(validateRs?.errorCode)) {
        router.replace(CART_URL);
        NotifyUtils.error(
          translateAlertAction(`message_error_cart.${validateRs?.errorCode}`) ||
          validateRs?.message ||
          translateAlertAction('message_error_checkout'),
        );
        return;
      }
      if (validateRs?.errorCode === TYPE_CUSTOMER_BY_COLOR.LOCKED_CUSTOMER && handleRedCustomer()) {
        return;
      }
      // APO-1559
      // gom những error code về sản phẩm thành 1 thông báo
      if (ERROR_PRODUCTS.includes(validateRs?.errorCode)) {
        NotifyUtils.error(translateAlertAction('message_error_products_cart'));
        onLoading(false);
        await updateCart();
        return;
      }

      if (validateRs?.errorCode === 'ORDER_MIN_1MILION') {
        const getErrorMessage = (errorData) => {
          const defaultMessage = translateAlertAction(`message_error_cart.ORDER_MIN_1MILION`);
          try {
            const { message, messageEN } = errorData.data[0];
            if (locale === 'th') {
              return message || defaultMessage;
            }
            return messageEN || defaultMessage;
          } catch {
            return defaultMessage;
          }
        };

        NotifyUtils.error(getErrorMessage(validateRs));
      } else {
        NotifyUtils.error(
          translateAlertAction(`message_error_cart.${validateRs?.errorCode}`) ||
          validateRs?.message ||
          translateAlertAction('message_error_checkout'),
        );
      }
      onLoading(false);
      BotClient.sendMesageBotOrderTh({ message: `TS validate error - ${user?.phone || ''}: ${JSON.stringify(validateRs || {})}` });
      return;
    }

    if (
      invoices &&
      invoices.length < 10 &&
      invoice &&
      invoice.invoiceRequest &&
      invoices.findIndex((item) => item.code === invoice.code) === -1 &&
      invoices.filter(
        ({ taxCode, companyName, companyAddress }) =>
          taxCode === invoice.taxCode && companyName === invoice.companyName && companyAddress === invoice.companyAddress,
      )?.length === 0
    ) {
      await CustomerClient.createInvoiceInfo({ body: invoice });
    }

    const response = await CartService.checkoutCart(formValue);

    if (isValid(response)) {
      // TODO: insider
      Insider.confirmation();
      // FB event tracking purchase
      const dataOrder = getFirst(response);
      if (dataOrder) {
        fbpixel.purchase({
          value: dataOrder.totalPrice,
          num_items: dataOrder.totalQuantity,
        });

        tiktok.purchase(dataOrder);
        tiktok.completePayment(dataOrder);

        // gtag
        // lọc cartItems được chọn thanh toán
        const GATrackCart = { ...cart, cartItems: cart?.cartItems?.filter((item) => item.isSelected) || [] };
        // load data seller nếu chưa có bằng hàm này
        await Promise.all(GATrackCart?.cartItems?.map((prd) => getSellerByCode({ code: prd?.sellerCode })));
        // lấy data seller từ store
        // nếu từ cart / sp là combo thì retailPrice là 1000 -> cần lấy lại giá theo cartItem
        GATrackCart.cartItems =
          GATrackCart?.cartItems?.map((prd) => ({ ...prd, sellerInfo: getSellerByCodeSync({ code: prd?.sellerCode }), displayPrice: prd.price })) ||
          [];

        gtag.purchase(GATrackCart);
      }

      if (isSaveInfo) {
        const address = {
          code: formValue.customerAddressCode,
          name: formValue.customerName,
          phone: formValue.customerPhone,
          email: formValue.customerEmail,
          address: formValue.customerShippingAddress,
          provinceCode: formValue.customerProvinceCode,
          districtCode: formValue.customerDistrictCode,
          wardCode: formValue.customerWardCode,
          regionCode: formValue.customerRegionCode,
        };
        if (!formValue.customerAddressCode) {
          const addressResp = await CustomerClient.createAddress({ body: address });
          if (!isValid(addressResp)) NotifyUtils.error(translateAlertAction('message_failed_save_address'));
        } else {
          const resp = await CustomerClient.updateAddress({ body: address });
          if (isValid(resp)) NotifyUtils.success(translateAlertAction('message_save_address'));
          else if (resp && resp.status === HTTP_STATUS.NotFound) {
            const addressResp = await CustomerClient.createAddress({ body: address });
            if (isValid(addressResp)) NotifyUtils.success(translateAlertAction('message_save_address'));
            else NotifyUtils.error(translateAlertAction('message_failed_save_address'));
          } else {
            NotifyUtils.error(translateAlertAction('message_failed_save_address'));
          }
        }
      }

      setTimeout(() => {
        updateCart();
      }, 1000);
      // clear products
      clearMapProduct();
      const { orderId = '' } = response.data[0] || {};

      // redirect based on payment method
      if (PAYMENT_METHOD_NEED_WAITING.includes(formValue.paymentMethod)) {
        router.push(`${WAITING_PAYMENT}/${orderId}`);
      } else {
        router.push(`${THANKYOU_URL}/${orderId}`);
      }
      onLoading(false);
    } else {
      if (response?.errorCode === TYPE_CUSTOMER_BY_COLOR.LOCKED_CUSTOMER && handleRedCustomer()) {
        return;
      }
      onLoading(false);
      BotClient.sendMesageBotOrderTh({ message: `TS checkout error - ${user?.phone}: ${JSON.stringify(response || {})}` });
      NotifyUtils.error(
        translateAlertAction(`message_error_cart.${response?.errorCode}`) || response?.message || translateAlertAction('message_error_checkout'),
      );
      setErrorMessage(
        `${translateAlertAction('message__failed_payment')}: ${translateAlertAction(`message_error_cart.${response?.errorCode}`) || response.message || translateAlertAction('message_system_error')
        }`,
      );
      updateCart();
    }
  };

  if (deliveryLimitData) {
    deliveryLimitData?.forEach((item) => {
      if (item?.wardCodes?.indexOf(ward) >= 0 || item?.districtCodes?.indexOf(district) >= 0 || item?.provinceCodes?.indexOf(province) >= 0) {
        deliveryLimitMessage = item?.message;
        deliveryLimitCheck = true;
      }
    });
  }

  const DisplayPrice = () => <div className={styles.total}>{FormatNumber.formatCurrency(Math.max(Math.max(totalPrice, 0), 0))}</div>;
  const DisplayPriceMobileNew = () => (
    <div className={styles.totalM}>
      <span>{translateCheckout('total_money')}</span>
      <p>{FormatNumber.formatCurrency(Math.max(totalPrice, 0))}</p>
    </div>
  );

  // del err items in cart
  const [openErrItems, toggleErrItems] = useModal();
  const handleDeleteErrProducts = async () => {
    try {
      await errProducts?.forEach(async (item) => {
        if (ARR_REMOVE_PRODUCT.indexOf(item?.errorCode) >= 0) {
          removeCartItem(item);
        }
        if (item?.errorCode === 'MAX_QUANTITY') {
          if (item?.isImportant) {
            await removeImportant(item);
          }
          selectCartItem({
            ...item,
            isSelected: !item.isSelected,
          });
        }
      });
      NotifyUtils.success(translateAlertAction('message_remove_all_error'));
      toggleErrItems();
    } catch (error) {
      NotifyUtils.error(error.message || translateAlertAction('message_remove_all_error_fail'));
    }
    router.reload();
  };

  // translate
  // const MapExtraFee = {
  //   // need to translate to Vi
  //   vi: () => (
  //     <>
  //       <Typography>
  //         Với đơn hàng dưới 1.5 triệu đồng (TP HCM và Hà Nội) hoặc dưới 2 triệu đồng (các tỉnh khác) sẽ bị phụ phí
  //         <b> {formatCurrency(50000)}</b>.
  //       </Typography>
  //       <Typography>
  //         Bạn còn cần <b>{formatCurrency(needToBuy())}</b> để được không bị tính phụ phí. Bạn muốn tiếp tục chứ?
  //       </Typography>
  //     </>
  //   ),
  //   // need to translate to Thai
  // };

  // multi voucher
  const getTotalDiscount = (autoRedeem = [], redeem = [], buymedServiceFee = 0) => {
    let total = 0;
    const validDiscounts = {
      auto: autoRedeem?.filter((child) => child.discountValue && typeof +child.discountValue === 'number'),
      redeem: redeem?.filter((child) => child.discountValue && typeof +child.discountValue === 'number'),
    };

    if (validDiscounts.auto && validDiscounts.redeem) {
      total =
        validDiscounts.auto?.reduce((acc, cur) => acc + cur.discountValue, 0) +
        validDiscounts.redeem?.reduce((acc, cur) => acc + cur.discountValue, 0);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (validDiscounts.auto) {
        total = validDiscounts.auto?.reduce((acc, cur) => acc + cur.discountValue, 0);
      } else if (validDiscounts.redeem) {
        total = validDiscounts.redeem?.reduce((acc, cur) => acc + cur.discountValue, 0);
      } else {
        total = 0;
      }
    }

    if (buymedServiceFee > 0) {
      total += buymedServiceFee;
    }

    return total > 0 ? `-${formatCurrency(total)}` : formatCurrency(total);
  };

  const methodSubTitle = () => {
    let content = '';
    if ([PAYMENT_METHOD.TRANSFER, PAYMENT_METHOD.COD, PAYMENT_METHOD.CREDIT].includes(customerPaymentInfo?.code)) {
      content = paymentSubTitle?.length > 0 ? paymentSubTitle.replace('{percentage}%', customerPaymentInfo?.additionFeeText || '') : '';
    } else if (customerPaymentInfo?.additionFeeText?.length > 0) {
      content = translateCheckout('addition_fee_text', { text: customerPaymentInfo?.additionFeeText || '' });
    }

    return content.length > 0
      ? (
        <div className={styles.d_flex} style={{ paddingTop: 0, maxWidth: '60%' }}>
          <div className={styles.checkout_label_second}>
            {content}
          </div>
        </div>
      )
      : <></>;
  };

  return (
    <div className={styles.checkout_sticky}>
      <Paper className={styles.root} elevation={4}>
        {/* order information - estimate total */}
        <div className={styles.d_flex}>
          <div className={styles.checkout_label}>{translateCheckout('order_information')}</div>
          <div className={styles.checkout_content}>
            <Button variant="outlined" color="primary" data-test="btn-checkout-edit" className={styles.btn} onClick={() => router.push('/cart')}>
              {translateCheckout('edit')}
            </Button>
          </div>
        </div>
        <div className={styles.d_flex}>
          <div className={styles.checkout_label}>{translateCheckout('total_products')}</div>
          <div className={styles.checkout_content}>{formatNumber(totalItemSelected)}</div>
        </div>
        <div className={styles.d_flex}>
          <div className={styles.checkout_label}>{translateCheckout('estimated_total')}</div>
          <div className={styles.checkout_content}>{formatCurrency(price)}</div>
        </div>

        {/* fees */}
        <Divider className={styles.dividerCustom} />
        {debt?.isActive && (
          <div className={styles.d_flex}>
            <div className={styles.checkout_label}>{translateCheckout('debt_remain')}</div>
            <div className={styles.checkout_content}>{debt.balanceFormated}</div>
          </div>
        )}
        {customerDeliveryInfo && deliveryMethodFee > 0 && locale && (
          <>
            <div className={styles.d_flex}>
              <div className={styles.checkout_label}>{deliveryFeeName || translateCheckout('delivery_fee')}</div>
              <div className={styles.checkout_content}>{formatCurrency(deliveryMethodFee)}</div>
            </div>
            {deliveryFeeSubTitle &&
              (
                <div className={styles.d_flex} style={{ paddingTop: 0, maxWidth: '60%' }}>
                  <div className={styles.checkout_label_second}>{deliveryFeeSubTitle}</div>
                </div>
              )}
          </>
        )}
        {customerPaymentInfo && paymentMethodFee !== 0 && locale && (
          <>
            <div className={styles.d_flex}>
              <div className={styles.checkout_label}>{paymentName || translateCheckout('checkout_fee')}</div>
              <div className={styles.checkout_content}>{customerPaymentInfo?.code !== PAYMENT_METHOD.CREDIT && formatCurrency(paymentMethodFee)}</div>
            </div>
            {/* {customerPaymentInfo?.subTitlePayment &&
              (
                <div className={styles.d_flex} style={{ paddingTop: 0, maxWidth: '60%' }}>
                  <div className={styles.checkout_label_second}>{customerPaymentInfo?.subTitlePayment}</div>
                </div>
              )} */}
            {methodSubTitle()}
          </>
        )}
        {extraFee && (
          <>
            <div className={styles.d_flex}>
              <div className={styles.checkout_label}>{translateCheckout('surcharge')}</div>
              <div className={styles.checkout_content}>{formatCurrency(extraFee)}</div>
            </div>
            {extraFeeNote && (
              <div className={styles.d_flex} style={{ paddingTop: 0, maxWidth: '60%' }}>
                <div className={styles.checkout_label_second}>{extraFeeNote}</div>
              </div>
            )}
          </>
        )}

        {(redeemRs || autoRedeemRs || totalBuymedServiceFee) && (
          <>
            <div className={`${styles.total_redeems}  ${styles.d_flex}`}>
              <Typography className={styles.checkout_label}>{translateCheckout('total_discount')}</Typography>
              <div className={styles.checkout_content}>{getTotalDiscount(autoRedeemRs, redeemRs, totalBuymedServiceFee)}</div>
            </div>
            {autoRedeemRs &&
              autoRedeemRs.length > 0 &&
              autoRedeemRs?.map((child) => (
                <div className={styles.d_flex}>
                  <Box display="flex" gridGap={4}>
                    <LocalOfferOutlined fontSize="small" />
                    <Typography style={{ color: '#A2A0A0', fontSize: '14px' }}>{child?.code}</Typography>
                  </Box>
                  {child?.discountValue > 0 && (
                    <Typography className={clsx(styles.checkout_content, styles.break_line)}>-{formatCurrency(child.discountValue)}</Typography>
                  )}
                  {child.gifts?.length > 0 && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography style={{ fontFamily: 'ggsm', marginRight: 5, color: '#000' }}>
                        x{child.gifts?.flat()?.reduce((accmulator, currentChild) => accmulator + currentChild?.quantity, 0) || 0}
                      </Typography>
                      <ImageFallbackStatic src={CART_NEW_GIFT_ICON} alt="icon gift" height="19" width="19" />
                    </div>
                  )}
                </div>
              ))}
            {redeemRs &&
              redeemRs.length > 0 &&
              redeemRs.map((child) => (
                <div className={clsx(styles.d_flex, styles.checkout_promo_code)}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <LocalOfferOutlined fontSize="small" />
                    <span style={{ color: '#A2A0A0', marginLeft: '4px', fontSize: '14px' }}>{child.code}</span>
                  </div>
                  {child.discountValue > 0 && (
                    <div className={clsx(styles.checkout_content, styles.break_line)}>{`-${formatCurrency(child.discountValue)}`}</div>
                  )}
                  {child.gifts?.length > 0 && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography style={{ fontFamily: 'ggsm', marginRight: 5, color: '#000' }}>
                        x{child.gifts?.flat()?.reduce((accmulator, currentChild) => accmulator + currentChild?.quantity, 0) || 0}
                      </Typography>
                      <ImageFallbackStatic src={CART_NEW_GIFT_ICON} alt="icon gift" height="19" width="19" />
                    </div>
                  )}
                </div>
              ))}
            {totalBuymedServiceFee > 0 && (
              <div className={styles.d_flex}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <LocalOfferOutlined fontSize="small" />
                  <span style={{ color: '#A2A0A0', marginLeft: '4px', fontSize: '14px' }}>{translateCheckout('buymed_payment_discount')}</span>
                </div>
                <div className={styles.checkout_content}>{`-${formatCurrency(totalBuymedServiceFee)}`}</div>
              </div>
            )}
          </>
        )}

        <Divider className={styles.dividerCustom} />
        <div style={{ margin: '12px 0' }} className={styles.d_flex}>
          <div className={styles.checkout_label} style={{ fontSize: 16 }}>
            {translateCheckout('total_money')}
          </div>
          <div className={styles.total}>{formatCurrency(Math.max(0, totalPrice))}</div>
        </div>
        <Divider className={styles.dividerCustom} />

        <div>
          <p style={{ padding: '8px 16px', color: '#000' }}>{translateCheckout('check_info_alert')}</p>
          <div className={styles.condition}>
            <FormControlLabel style={{ padding: '8px 16px' }} control={GreenCheckBoxElement} label={LabelConfirm} />
            <div className={styles.list_note}>
              <p>1. {translateCheckout('alert_cancel').replace('{BRAND_NAME}', BRAND_NAME)}</p>
              <p>2. {translateCheckout('alert_wrong_quantity')}</p>
            </div>
          </div>
        </div>

        {!isMobile && (
          <div style={{ padding: '0 16px', color: '#000' }}>
            <Tooltip title={checkCondition.checked && translateCheckout('agree_with_condition')}>
              <ButtonDefault
                disabled={!checkCondition.checked || deliveryLimitCheck || isOverWeightOrVolume || (debt && debt.debtBalance <= 0)}
                className={clsx('payment_button', styles.continue_btn)}
                onClick={extraFee ? toggleConfirm : handleSubmit}
                data-test="btn-checkout-payment"
              >
                {translateCheckout('checkout')}
              </ButtonDefault>
            </Tooltip>
          </div>
        )}
      </Paper>

      <div className={styles.text_right}>
        <VerifyPaymentDiablog visible={showModal} />
        {errorMessage && (
          <div className={styles.condition}>
            <Alert severity="error" style={{ marginTop: '10px', borderRadius: '8px' }}>
              {errorMessage}
            </Alert>
          </div>
        )}
        {deliveryLimitMessage && (
          <div className={styles.condition}>
            <Alert severity="error" style={{ marginTop: '10px', borderRadius: '8px' }}>
              {deliveryLimitMessage}
            </Alert>
          </div>
        )}

        {/* // TODO: feature-debt */}
        {debt?.balance <= 0 && (
          <div className={styles.condition}>
            <Alert severity="error" style={{ marginTop: '10px', borderRadius: '8px' }}>
              {debt?.balanceMessageError}
            </Alert>
          </div>
        )}

        {FEATURE_SHOW_BUTTON_VERIFY_CART && (
          <ButtonDefault btnType="success" className={styles.checkout_btn} onClick={handleVerify}>
            {translateCheckout('check')}
          </ButtonDefault>
        )}

        {isMobile && (
          <div className={clsx(styles.sticky_checkout_bar_mobile, beta && styles.sticky_checkout_bar_mobileNew)}>
            <div className={styles.fwc_container}>
              <div className={styles.flex_price}>{beta ? <DisplayPriceMobileNew /> : <DisplayPrice />}</div>
              <div>
                {/* /// TODO: feature-debt */}
                <ButtonDefault
                  disabled={!checkCondition.checked || user.isQuest || deliveryLimitCheck || isOverWeightOrVolume || (debt && debt.balance <= 0)}
                  btnType={beta ? 'primary' : 'warning'}
                  onClick={extraFee ? toggleConfirm : handleSubmit}
                  classes={{
                    label: styles.label,
                    outlined: styles.outlined,
                    root: beta ? styles.mobileRoot_btn : styles.root_btn,
                  }}
                >
                  {translateCheckout('checkout')}
                </ButtonDefault>
              </div>
            </div>
          </div>
        )}
      </div>
      {errProducts?.length > 0 && !isMobile && (
        <div className={styles.card_err_container}>
          <Typography className={styles.text_err_notification}>
            {translateCheckout('message_error_products_in_cart')}
            <b className={styles.delete_text} role="presentation" onClick={toggleErrItems}>
              <DeleteIcon fontSize="small" /> {translateCheckout('click_here_to_delete')}
            </b>
          </Typography>
          <ErrorProductsPayment />
        </div>
      )}
      {openErrItems && (
        <ModalListProduct
          products={errProducts}
          isShowPopupConfirm={openErrItems}
          handleClosePopup={toggleErrItems}
          handleClick={handleDeleteErrProducts}
          title={translateCheckout('confirmation')}
          content={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <>
              <Typography className={styles.text_content_modal} style={{ fontWeight: '700', marginTop: '15px' }}>
                {translateCheckout('message_delete_all_error_products')}
              </Typography>
              <Typography className={styles.text_content_modal} style={{ marginTop: '20px' }}>
                {translateCheckout('message_exceed_back')}
              </Typography>
            </>
          }
        />
      )}
      <CustomModal
        visible={showConfirmModal}
        onClose={toggleConfirm}
        title={translateCheckout('confirmation')}
        btnOk={translateCheckout('confirm')}
        onClickOk={handleSubmit}
        btnOnClose={translateCheckout('check_cart')}
      >
        {/* {extraFee && MapExtraFee[LANG_DEFAULT]()} */}
      </CustomModal>
    </div>
  );
};

export default CheckoutSticky;
